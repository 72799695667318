import scotConfig from '../config'

export const BASE_URL = scotConfig.REACT_APP_WEB_API_BASE_URI

export const GET_NEWS = '/api/News/GetNews'
export const POST_NEWS = '/api/News/CreateNews'
export const PUT_NEWS = '/api/News/UpdateNews'
export const DELETE_NEWS = '/api/News/DeleteNews'
export const GET_NEWS_BY_ID = '/api/News/GetNewsById'

export const SEARCH_MYESOW_ALL = '/api/BasicSearch/GetEsowDocumentsByQuery'
export const SEARCH_QUICK_BY_STRING = '/api/BasicSearch/GetEsowDocumentsBySearchString'
export const SEARCH_MYESOW_SUPPLIER = '/api/BasicSearch/GetEsowDocumentsByQueryBySupplier'

export const GET_MYESOW = '/api/EsowDocuments/GetAllEsowDocuments'
export const GET_ESOW_STDUSR = '/api/EsowDocuments/GetAllEsowDocumentsContributors'
export const GET_ESOW_SUPPLIER = '/api/EsowDocuments/GetAllEsowDocumentsSuppliers'
export const SAVE_ESOW_HEADER = '/api/EsowDocuments/SaveEsowHeader'
export const GETSECTION_BY_DOCID = '/api/EsowDocuments/GetSectionsByEsowDocumentId'
export const GETRULES_BYSECTION = '/api/EsowDocuments/GetEsowDocumentDataBySectionId'

export const GET_ESOW_CATEGORIES = '/api/EsowCategories/GetEsowCategories'
export const GET_ESOW_CATEGORIES_STATUS = '/api/EsowCategories/GetEsowCategoriesStatusByEsowId'
export const CREATE_ESOW_DOC = '/api/EsowDocuments/CreateEsowDocument'
export const SAVE_ESOW_MAIN = '/api/EsowDocuments/SaveEsowMainSectionData'
export const GET_ALL_PARAGRAPHS = '/api/Paragraphs/GetAllParagraphs'
export const GET_PARAGRAPHSBY_DOC = '/api/Paragraphs/GetParagraphStatusByEsowId'
export const GET_PARAGRAPHSBYFILE_UPLOAD = '/api/Paragraphs/GetFileuploadTextParagraphsByEsowId'
export const SAVE_PARAGRAPHS = '/api/Paragraphs/SaveEsowParagraphs'
export const ACCEPT_NEW_VERSION = '/api/Paragraphs/AcceptNewParagraphs'
export const ACCEPT_NEW_PARAADD = '/api/Paragraphs/ActionOnNewParagraphEsow'
export const DECLINE_NEW_VERSION = '/api/Paragraphs/DeclineNewParagraphs'
export const GET_ALL_SUPPLIER = '/api/Supplier/GetAllSuppliers'
export const GET_SUPPLIERS = '/api/Supplier/GetSuppliers'
export const GET_SUPPLIER_DETAILS = '/api/Supplier/GetSupplierDetails'
export const CREATE_SUPPLIER_ESOW_DOC = '/api/EsowDocuments/CreateSupplierEsow'
export const CREATE_COPYOF_MASTER_ESOW = '/api/EsowDocuments/CopyMasterEsow'
export const DELETE_ESOW = '/api/EsowDocuments/DeleteEsowDocument'
export const COPY_NEW_ROUND = '/api/EsowDocuments/NewSupplierEsow'

export const SAVE_PTYPE_FILE = '/api/FileUpload/SavePTypeFileUpload'
export const GET_FILE_DOWNLOAD = '/api/FileUpload/GetDocumentByBlobName'
export const DELETE_BLOB_BYURI = '/api/FileUpload/DeleteBlobByUri'
export const SAVE_GENERATED_PDF = '/api/FileUpload/SaveGeneratedPdf'

export const GET_DOCS_BYPARAGRAPH = '/api/FileUpload/GetDocumentByParagraph'
export const ESOW_SUPPLIER_DETAILS_MAPPING = '/api/Supplier/EsowDocumentSupplierDetailsMapping'
export const GET_ESOW_CONTRIBUTOR_VCC = '/api/ContributorVcc/GetContributorVcc'
export const SAVE_ESOW_CONTRIBUTOR_VCC = '/api/ContributorVcc/SaveContributorVcc'
export const GET_ESOW_SIGNERS = '/api/Signer/GetSigners'
export const SAVE_ESOW_SIGNERS = '/api/Signer/SaveSigners'
export const GET_ESOW_PARAGRAPH_MESSAGES = '/api/Messages/GetMessagesByParagraphId'
export const SAVE_ESOW_PARAGRAPH_MESSAGE = '/api/Messages/CreateMessages'
export const DELETE_ESOW_PARAGRAPH_MESSAGE = '/api/Messages/DeleteMessage'

export const GENERATE_ESIGN_ZIP = '/api/ESign/GenerateZip'
export const GENERATE_ESIGN_ZIP_NOSAVE = '/api/ESign/GenerateZipWithoutSave'
export const CREATE_ESIGN = '/api/esign/CreateEsignAgreement'
export const GET_ZIPINFO = '/api/ESign/CheckForZipFile'
export const GET_ESIGN_EVENTS = '/api/esign/GetAgreementEvents'
export const GET_ESIGN_PDF = '/api/esign/GetDocumentByAgreement'
export const DELETE_ESIGN_AGREEMENT = '/api/esign/DeleteAgreementDetails'

export const GET_DROPDOWNOPTIONS = '/api/DropdownOptions/GetDropdownOptions'
export const CREATE_DROPDOWNOPTIONS = '/api/DropdownOptions/CreateDropdownOptions'
export const UPDATE_DROPDOWNOPTIONS = '/api/DropdownOptions/UpdateDropdownOptions'
export const DELETE_DROPDOWNOPTIONS = '/api/DropdownOptions/DeleteDropdownOptions'

export const PING_PONG = '/api/News/Ping'

export const GET_PARAGRAPH_MESSAGES_SIGNIN_SIGNOUT_DETAILS =
    '/api/Messages/GetParagraphMsgsSigninSignoutDetailsBySndIdPrgId'

export const CREATE_UPDATE_PARAGRAPH_MESSAGES_SIGNIN_SIGNOUT_DETAILS =
    '/api/Messages/CreateParagraphSigninSignoutDetails'

export const GET_MESSAGES_UNREAD =
    '/api/Messages/GetUnreadMessagesBySupplierId/GetUnreadMessagesBySupplierId'
export const GET_ALL_MESSAGES_BY_ESOWID = '/api/Messages/GetAllMessagesByEsowId'
export const GET_ALL_MESSAGES = '/api/Messages/GetAllMessages'
export const GET_EXPORTCONTROLDISCLAIMER_CONTENT_TEXT =
    '/api/ExportControlDisclaimer/GetExportControlDisclaimerContentText'
export const GET_EXPORTCONTROLDISCLAIMER_ACCEPT_STATUS =
    '/api/ExportControlDisclaimer/GetExportControlDisclaimerAcceptStatus'
export const SET_EXPORTCONTROLDISCLAIMER_ACCEPT_STATUS =
    '/api/ExportControlDisclaimer/SaveExportControlDisclaimerAcceptStatus'
export const GET_ESOW_USER_ACTIVITY_LOGS_BY_ESOWID = '/api/UserActivity/GetUserActivityDeatils'
export const GET_ESOW_USER_ACTIVITY = '/api/UserActivity/GetUserActivitiessByParagraphId'
export const REMOVE_SIGNING_DOCS = '/api/FileUpload/DeleteGeneratedSigningDocs'
export const GET_ADDITIONAL_SUPPLIERS = '/api/Supplier/GetAdditionalSuppliers'
export const GET_SELECTED_ADDITIONAL_SUPPLIERS = '/api/Supplier/GetSelectedAdditionalSuppliers'
export const CREATE_ADDITIONAL_SUPPLIER_MAPPING =
    '/api/Supplier/EsowDocumentAdditionalSupplierMapping'
export const GET_ALL_ACKNOWLEDGEMENTS = '/api/EsowDocuments/GetESOWAcknowledgements'
export const GET_MY_ACKNOWLEDGEMENTS = '/api/EsowDocuments/GetMyAcknowledgements'
export const GET_USER_EMAIL_PREFERENCES = '/api/EmailPreferences/GetEmailPreferencesDetailsByUserId'
export const SAVE_USER_EMAIL_PREFERENCES = '/api/EmailPreferences/UpdateEmailPreferencesDetails'
export const GET_USER_DETAILS = '/api/UserActivity/GetUsers'
export const GET_EXPORTCONTROLDISCLAIMER_ACCEPT_STATUS_BY_CDSID =
    '/api/ExportControlDisclaimer/GetExportControlDisclaimerAcceptStatusByCDSID'
export const GET_ALL_ECD_ACKNOWLEDGEMENTS = '/api/EsowDocuments/GetAllECDAcknowledgements'
export const GET_MY_ECD_ACKNOWLEDGEMENT = '/api/EsowDocuments/GetMyECDAcknowledgement'

export const GET_CHANGELOG = '/api/ChangeLog/GetChangeLog'
export const GET_VERSION_LATEST = '/api/ChangeLog/GetVersion'
export const POST_CHANGELOG = '/api/ChangeLog/CreateChangeLog'
export const PUT_CHANGELOG = '/api/ChangeLog/UpdateChangeLog'
export const DELETE_CHANGELOG = '/api/ChangeLog/DeleteChangeLog'
export const GET_CHANGELOG_BY_ID = '/api/ChangeLog/GetChangeLogById'
export const DELETE_PARAGRAPH_BY_ESOWID_PARAGRAPHID = '/api/Paragraphs/DeleteParagraph'
export const POST_PARAGRAPH_READ_STATUS = '/api/ParagraphReadStatus/SaveParagraphReadStatus'
